<template>
  <div class="outer-page">
    <div class="sub-banner">
      <img src="../../../../public/imgs/backgroundImg/01.jpg" alt="" />
      <div class="nav-bar">
        <nav-bar></nav-bar>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@components/mobile/navBar";
export default {
  name: "",
  components: {
    navBar,
  },
  data() {
    return {};
  },
  computed: {
    subMenu() {
      return this.$store.state.permission.subMenu || {};
    },
    currentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .sub-banner {
    img {
      height: 5.59rem;
      width: 100%;
    }
    .nav-bar {
      padding: 0.3rem 0.5rem;
    }
  }
}
</style>