<template>
  <div class="mobile-main-page" :style="{overflowY: scroll}">
    <mobile-header-bar @is-scroll="isScroll"></mobile-header-bar>
    <sub-banner></sub-banner>
    <router-view></router-view>
    <mobile-footer></mobile-footer>
  </div>
</template>

<script>
import mobileHeaderBar from "@components/mobile/mobileHeaderBar"
import mobileFooter from "@components/mobile/mobileFooter"
import subBanner from "@components/mobile/subBanner"
export default {
  name: "",
  components: {
    mobileHeaderBar,
    mobileFooter,
    subBanner,
  },
  data() {
    return {
      scroll: 'auto'
    };
  },
  methods: {
    isScroll(scroll) {
      this.scroll = scroll
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-main-page {
  width: 100%;
}
</style>