<template>
  <div class="nav-pane" v-if="subMenu.childList">
    <div
      :class="['prev', piexl === 0 ? 'prev-disabled' : '']"
      @click="prev"
    ></div>
    <div class="nav-box" ref="navBox">
      <div
        :style="{
          left: `${piexl}rem`,
        }"
      >
        <!-- <span
          class="nav-item"
          v-for="(item, index) in subMenu.childList"
          :key="index"
          @click="enter(index)"
          >{{ item.name }}</span
        > -->
        <span
          :class="[
            'nav-item',
            currentRoute == item.router ? 'nav-item-active' : '',
          ]"
          v-for="(item, index) in subMenu.childList"
          :key="index"
          @click="() => goThere(item.router, item.id)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div
      :class="[
        'next',
        piexl === -(subMenu.childList.length - 3) * 4.42 ? 'next-disabled' : '',
      ]"
      @click="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    navData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    subMenu() {
      return this.$store.state.permission.subMenu || {};
    },
    currentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  data() {
    return {
      piexl: 0,
      // navItemShow: "",
    };
  },
  methods: {
    prev() {
      if (this.piexl === 0) return;
      this.piexl += 4.42;
    },

    text() {
      if (this.piexl <= -(this.subMenu.childList.length - 3) * 4.42) return;
      this.piexl -= 4.42;
    },
    enter(index) {
      this.$emit("on-change", index);
    },
    goThere(router, id) {
      // if (this.currentRoute === router) return;
      // this.navItemShow = id;
      this.$router.push(
        { name: "m" + router },
        () => {},
        () => {}
      );
    },
  },
  mounted() {
    // console.log(window.innerWidth)
    // this.navItemShow =
    //   this.subMenu.childList && this.subMenu.childList.length > 0
    //     ? this.subMenu.childList[0].id
    //     : "";
    // if (this.subMenu.childList && this.subMenu.childList.length > 0) {
    //   let index = this.subMenu.childList.findIndex(
    //     (item) => item.router == this.currentRoute
    //   );
    //   if (index > 1) {
    //     this.piexl = -(index - 2) * 4.42;
    //   } else {
    //     this.piexl = 0;
    //   }
    // }
  },
  watch: {
    subMenu: {
      immediate: true,
      handler(n) {
        if (n.childList && n.childList.length > 0) {
          // let navItem = n.childList.filter(
          //   (item) => this.navItemShow == item.id
          // );
          // if (navItem.length == 0) {
          //   this.navItemShow = n.childList[0].id;
          // }
          let index = n.childList.findIndex(
            (item) => item.router == this.currentRoute
          );
          if (index > 1) {
            this.piexl = -(index - 2) * 4.42;
          } else {
            this.piexl = 0;
          }
        }
      },
    },
    // $route() {
    //   if (this.subMenu.childList && this.subMenu.childList.length > 0) {
    //     let index = this.subMenu.childList.findIndex(
    //       (item) => item.router == this.currentRoute
    //     );
    //     if (index > 1) {
    //       this.piexl = -(index - 2) * 4.42;
    //     } else {
    //       this.piexl = 0;
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.nav-pane {
  width: 100%;
  position: relative;
  .nav-box {
    width: 89%;
    height: 1.4rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    > div {
      position: absolute;
      // margin-left: 4.5rem;
      left: 0;
      top: 0;
      display: flex;
      transition: all 0.5s ease-in-out;
      .nav-item {
        display: inline-block;
        width: 3.92rem;
        height: 1.4rem;
        text-align: center;
        line-height: 1.4rem;
        border-radius: 1.4rem;
        background: #245f5c;
        font-size: 0.533rem;
        color: #fff;
        margin: 0 0.25rem;
      }
      .nav-item-active {
        color: #fff;
        background: #ff9c00;
      }
    }
  }
  .prev {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: url(../../../../public/imgs/c-left01.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .prev-disabled {
    background: url(../../../../public/imgs/c-left11.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .next {
    position: absolute;
    top: 0.3rem;
    right: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: url(../../../../public/imgs/c-right01.png) left center no-repeat;
    background-size: 100% 100%;
  }
  .next-disabled {
    background: url(../../../../public/imgs/c-right11.png) left center no-repeat;
    background-size: 100% 100%;
  }
}
</style>