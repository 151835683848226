<template>
  <div class="outer-page">
    <div class="footer">
      <div class="centre-width">
        <div class="foot-img">
          <img
            :src="companyInfo.servicePath + companyInfo.companyRqcode"
          />
        </div>
        <div class="copy-right">
          <p>
            网站建设：<a
              style="color: #ffffff"
              :href="companyInfo.companyLink"
              target="blank"
              >{{ companyInfo.companySysSupport }}</a>
          </p>
          <p>版权所有：{{ companyInfo.companyName }}</p>
          <p>
            <a
              href="https://beian.miit.gov.cn/"
              style="color: #ffffff"
              target="blank"
              >{{ companyInfo.websiteNumber }}</a
            >
          </p>
        </div>
        <div class="clears"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      companyInfo: [],
    };
  },
  methods: {
    getList() {
      this.$post("getcompany").then((res) => {
        this.companyInfo = res;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .footer {
    padding: 0.667rem 0;
    line-height: 1rem;
    color: #ffffff;
    font-size: 0.53rem;
    background: #333333;
    .centre-width {
      width: 94%;
      margin: 0 3%;
      .foot-img {
        float: left;
        img {
          display: block;
          width: 4.8rem;
          height: 4.8rem;
          border: 0.2rem #ffffff solid;
          background-color: #fff;
        }
      }
      .copy-right {
        float: right;
        width: 60%;
        font-size: 0.488rem;
      }
      .clears {
        clear: both;
        line-height: 0;
        overflow: hidden;
        font-size: 0;
        height: 0;
      }
    }
  }
}
</style>